.eComposer-container {
  width: 100%;
  background: #f6f6f6;
  padding-top: 25px;
  padding-bottom: 25px;
}
.cAvailable {
  background: #e9e9e9;
  height: 33px;
  font-size: 14px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 197px;
  border-radius: 7px;
  margin-bottom: 29px;
}
.containerDay {
  border: 0px;
  width: 280px;
  padding-left: 15px;
  font-size: 15px;
  min-width: 200px;
}
.textAvailable {
  color: #007cff;
  font-weight: bold;
}
.eContainer {
  width: 80%;
  background: white;
  padding-top: 26px;
  padding-bottom: 25px;
  border-radius: 7px;
  margin-right: auto;
  margin-left: auto;
  /*-webkit-box-shadow: 1px 2px 7px -5px rgba(0,0,0,0.64);
    -moz-box-shadow: 1px 2px 7px -5px rgba(0,0,0,0.64);
    box-shadow: 1px 2px 7px -5px rgba(0,0,0,0.64);*/
  box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 0.5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 0.5);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 0.5);
  position: relative;
}
.textContainer {
  width: 79%;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  text-align: center;
  color: #4a4a4a;
}
.eContainer-title {
  font-size: 25px;
  font-weight: bold;
  line-height: 1.04;
  text-align: center;
  color: #007cff;
  margin-bottom: 31px;
}
.squareMaster {
  width: 125px;
  height: 124px;
  display: flex;
}
.camS {
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  display: flex;
}
.input-file {
  opacity: 0;
  position: absolute;
  width: 124px;
  height: 124px;
  display: flex;
}
.textCam {
  font-size: 12px;
  text-align: center;
  color: #9b9b9b;
  padding-top: 9px;
  position: relative;
}
.box1 {
  width: 124px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.box2 {
  width: 124px;
  height: 124px;
  margin-left: auto;
  margin-right: auto;
  background: #ebebeb;
  border-radius: 5px;
}
.fromEmail {
  width: 79%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
}

.textSubject {
  width: 100%;
  height: 41px;
  border-radius: 5px;
  border: solid 1px #9b9b9b;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  padding-left: 20px;
  margin-bottom: 20px;
}
.txtEmail {
  width: 100%;
  margin-top: 16px;
  padding-left: 20px;
  padding-top: 13px;
  padding-right: 45px;
  text-align: left;
  border-radius: 5px;
}
.txtDeadline {
  color: #9b9b9b;
  border-radius: 5px;
  border: solid 1px #9b9b9b;
  font-size: 16px;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 3px;
  width: 100%;
}
.txtDeadline form,
.txtDeadline .DayPickerInput,
.txtDeadline .containerDay {
  width: 100%;
}
.faqDay {
  width: 30px;
  position: absolute;
  margin-left: 88%;
}
.txtPlaceDead {
  color: #9b9b9b;
  width: 90%;
  border: 0;
  padding-left: 15px;
  padding-top: 6px;
}
.textSave {
  margin-left: auto;
  margin-right: auto;
  margin-top: 31px;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: white;
  padding-bottom: 10px;
  padding-top: 6px;
  width: 293px;
  height: 41px;
  border-radius: 5px;
  background-color: #0080ff;
  border: solid 1px #0077ff;
}
.previewComponent {
  position: absolute;
  z-index: 1;
}
