.handbook_wrapper {
  display: flex;
  position: relative;
}
.handbook_side {
  background-color: #f5f5f5;
  padding-top: 10px;
}
.handbook_side li {
  margin-bottom: 15px;
}
.modalHandbook li {
  list-style-type: none;
}
.modalHandbook ul {
  margin-block-start: 0px;
  padding-inline-start: 0px;
  padding-left: 20px;
  padding-right: 20px;
}
.cancelSimbol {
  position: absolute;
}
.handbook_title {
  font-family: "Dosis";
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
  color: #377fff;
  margin-bottom: 20px;
}
.handbook_content {
  flex: 1;
}
.handbook_center {
  text-align: center;
  margin-bottom: 20px;
}
.handbook_pills {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.hanbook_bold {
  font-weight: bold;
}
.handbook_pill {
  background-color: #f5f5f5;
  color: #377fff;
  font-family: "Dosis";
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  border-radius: 5px;
}
.handbook_first_pill {
  margin-right: 20px;
}
.handbook_list_wrapper {
  display: flex;
  justify-content: center;
}
.handbook_list {
  display: flex;
  width: 300px;
  justify-content: flex-start;
  flex-direction: column;
}
.handbook_list li span {
  color: #377fff;
  font-weight: bold;
}
