.cPlan {
  width: 100%;
  padding-top: 20px;
}
.cPlan-title {
  font-weight: bold;
  font-size: 20px;
  padding-top: 11px;
  padding-bottom: 47px;
  color: #0077ff;
  letter-spacing: 0;
  text-align: center;
}
.choose-title {
  font-weight: bold;
  font-size: 18px;
  color: #0077ff;
  letter-spacing: 0;
  text-align: center;
  z-index: 0;
  float: left;
  width: 100%;
  margin-left: auto;
  margin-bottom: 10px;
  margin-right: auto;
}
.cPlan-container {
  width: 100%;
  background: #f6f6f6;
}
.planContainer {
  width: 80%;
  background: white;
  padding-top: 26px;
  padding-bottom: 25px;
  border-radius: 7px;
  margin-right: auto;
  margin-left: auto;
  /*-webkit-box-shadow: 1px 2px 7px -5px rgba(0,0,0,0.64);
    -moz-box-shadow: 1px 2px 7px -5px rgba(0,0,0,0.64);
    box-shadow: 1px 2px 7px -5px rgba(0,0,0,0.64);*/
  box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 0.5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 0.5);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 0.5);
}
.cancelChooseCont {
  width: 1.3em;
  float: right;
  position: absolute;
  z-index: 1;
  opacity: 0.7;
}
.cancelChoose {
  width: 1.3em;
  float: right;
  position: absolute;
  z-index: 1;
  opacity: 0.7;
}
.row {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.row2 {
  width: 64%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 14px;
}
.lineTitle {
  width: 108%;
}
.cardPlan {
  width: 221px;
  height: 275px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 30px 0px rgba(79, 79, 79, 0.5);
  -moz-box-shadow: 0px 0px 30px 0px rgba(79, 79, 79, 0.5);
  -webkit-box-shadow: 0px 0px 30px 0px rgba(79, 79, 79, 0.5);
}
.txtCardHead {
  width: 218px;
  float: left;
  position: absolute;
  padding-top: 11px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: white;
}
.txtCardHeadB {
  width: 218px;
  float: left;
  position: absolute;
  padding-top: 11px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #017cfe;
}
.imgCardHead {
  position: absolute;
  border-radius: 5px;
}
.txtCardDes {
  text-align: center;
  font-size: 14px;
  padding-top: 55px;
  padding-left: 13px;
  padding-right: 13px;
  line-height: 17px;
  margin-bottom: 15px;
}
.txtCardDes2 {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #4a4a4a;
}
.txtCardPrice {
  height: 48px;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: #007cff;
  line-height: 43px;
}
.txtCardPrice2 {
  height: 48px;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: #007cff;
  line-height: 43px;
  margin-bottom: 20px;
}
.txtCardPertest {
  font-size: 15px;
  text-align: center;
  color: #007cff;
  line-height: 5px;
  margin-bottom: 15px;
}
.txtCardTotal {
  height: 16px;
  font-size: 14px;
  text-align: center;
  color: #9b9b9b;
  margin-bottom: 10px;
}
.txtCardButton {
  width: 196px;
  height: 40px;
  border-radius: 5px;
  background-color: #004080;
  margin-top: 10px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 6px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}
.cardButton2 {
  cursor: pointer;
  width: 196px;
  height: 40px;
  border-radius: 5px;
  background-color: #004080;
  color: white;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 6px;
  margin-left: 13px;
  margin-right: auto;
  margin-bottom: 15px;
  border: 0px;
}
.cardButton:hover {
  background-color: #02315f;
}
.cardButton2:hover {
  background-color: #02315f;
}
.cardButton {
  cursor: pointer;
  width: 196px;
  height: 40px;
  border-radius: 5px;
  background-color: #004080;
  color: white;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 6px;
  margin-left: 13px;
  margin-right: auto;
  margin-bottom: 15px;
  border: 0px;
}
.cancelChoose2 {
  float: right;
  width: 20px;
  margin-right: 20px;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
}
