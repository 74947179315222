.drawer {
  background-color: white;
  height: 100%;
}
.drawer .title {
  background: url("../../images/drawerBackground.png");
  height: 164px;
  width: 101%;
  background-size: cover;
}
.drawer ul.list {
  list-style-type: none;
  top: 20px;
  position: relative;
  margin-block-start: 0px;
  padding-inline-start: 0px;
}
.drawer ul.list li {
  cursor: pointer;
  margin-bottom: 10px;
  padding-left: 20px;
  height: 40px;
  display: flex;
  align-items: center;
}
.drawer ul.list li:hover {
  background-color: lightgray;
}
.drawer ul.list li img {
  width: 11px;
  background-size: cover;
}
.drawer ul.list li span {
  position: relative;
  left: 15px;
}
.space-bar {
  justify-content: space-between;
}
.center-bar {
  justify-content: center;
}
.dummy-title-button {
  width: 37.7188px;
}
.home {
  min-height: 100%;
}
.blue-text {
  font-family: "Dosis";
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #007cff;
}
.sub-li {
  height: 0px;
  transition: height 1s ease;
  overflow-y: hidden;
}
.sub-li.show {
  height: 90px;
}
