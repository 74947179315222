.hbutton {
  width: 100%;
  height: 41px;
  text-align: center;
  border: 0;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  font-size: 15px;
  padding: 0px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  
}
.hbutton img{
  position: relative;
    height: 15px;
    right: 20px;
}
.hbutton.primary {
  background: #0080ff;
  border-radius: 10px;
  color: white;
}
.hbutton.secondary {
  background: #001fff;
  border-radius: 10px;
  color: white;
}
.hbutton.linked {
  background: #0081c1;
  border-radius: 5px;
  color: white;
}
.hbutton.plain {
  color: #007cff;
  background: #ffffff;
}
.hbutton.third {
  font-family: "sf_compact_display";
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #9b9b9b;
  background: white;
}
.hbutton.only-border {
  border: 1px solid #0a84ff;
  border-radius: 100px;
  background: #ffffff;
  color: #0a84ff;
}

.hbutton:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% / 40);
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(40, 40);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(50, 50);
  }
}

.hbutton:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}
