.slide.header {
  text-align: center;
  font-weight: bold;
  color: white;
}
.slide.text {
  text-align: center;
  color: white;
}
.sliderWhite .slick-dots li button:before {
  color: white;
  opacity: 0.5;
}
.sliderWhite .slick-dots li.slick-active button:before {
  color: white;
  opacity: 1;
}
