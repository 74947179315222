.canvasBox {
  background-color: red;
  width: 400px;
  height: 400px;
}
.bodyVideo {
  height: 98%;
  background-color: #f6f6f6;
  padding-top: 40px;
}
.row.rowShadow {
  box-shadow: 0 0 10px 0 rgba(155, 155, 155, 0.5);
  border-radius: 5px;
}
.col.containerVideo {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  position: relative;
  padding: 0px;
  padding-left: 5px;
  padding-right: 5px;
  min-height: 28em;
  max-height: 34em;
}
.blueBox {
  width: 54%;
  height: 52px;
  opacity: 0.7;
  border-radius: 100px;
  background-color: #007cff;
  margin-right: auto;
  margin-left: auto;
  padding-right: 6px;
  padding-left: 6px;
}
.btnVideoRec {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  position: absolute;
  bottom: -30px;
  left: 0px;
  display: flex;
  justify-content: center;
}
.btnRec {
  background-image: url("../../images/btnRecorder.svg");
  height: 65px;
  width: 65px;
  background-repeat: no-repeat;
  border: 0px;
  border-radius: 50px;
  cursor: pointer;
}
.btnReview {
  position: absolute;
  bottom: -28px;
  height: 65px;
  width: 65px;
  background: #007cff;
  border: 3px solid rgba(0, 0, 0, 0.4);
  border-radius: 50px;
  font-family: "sf_compact_display";
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  padding-left: 0px;
  padding-right: 0px;
  cursor: pointer;
}
.boxBtnReRecord {
  margin-left: 0;
  margin-bottom: 0px;
  bottom: 90px;
}
.row.btnsRecorder {
  width: 100%;
  right: 0px;
  left: 0px;
  bottom: 29px;
  position: absolute;
}
.btnReRecord {
  bottom: -45px;
  width: 114px;
  height: 44px;
  border-top-right-radius: 150px;
  border-bottom-right-radius: 150px;
  border: 0;
  background: #007cff;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.btnSaveVideo {
  bottom: -45px;
  width: 114px;
  height: 44px;
  border-top-left-radius: 150px;
  border-bottom-left-radius: 150px;
  border: 0;
  background: #007cff;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.boxBlue {
  border-radius: 100px;
  background-color: rgba(0, 124, 255, 0.7);
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  font-family: 14px;
  line-height: 17px;
  color: #ffffff;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
  max-width: 438px;
  min-width: 262px;
  max-height: 74px;
  min-height: 52px;
  line-height: 15px;
  z-index: 1;
  position: relative;
}
.boxBlack {
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.7);
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  font-family: 14px;
  line-height: 17px;
  color: #ffffff;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
  max-width: 438px;
  min-width: 280px;
  max-height: 74px;
  min-height: 52px;
  line-height: 15px;
  z-index: 1;
  position: relative;
}
.videoBoxClass {
  position: absolute;
  z-index: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.progressbar-red {
  height: 65px;
  width: 65px;
  cursor: pointer;
}
.progressbar-red .CircularProgressbar-path {
  stroke: #f44336;
}
.CircularProgressbar .CircularProgressbar-text {
  fill: #ffffff !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  text-anchor: middle !important;
  dominant-baseline: middle !important;
}
.CircularProgressbar.btnRec.progressbar-red {
  fill: #b1b1b1;
}
.progressbar-red .CircularProgressbar-trail {
  stroke: #7ed321;
}
.boxPlayRec {
  width: 73px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 170px;
  position: relative;
}
.playRec {
  width: 73px;
  height: 73px;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  padding-top: 14px;
  padding-left: 5px;
  border-radius: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.textSkip {
  color: #ffffff;
}
.btnSkip {
  color: #ffffff;
  border: 0px;
  font-size: 18px;
}
.btn.btn-link.btnSkip {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
}
.row.justify-content-end.rowBlackSkip {
  position: absolute;
  top: 5px;
  right: 0px;
}
.vjs-device-button {
  display: none !important;
}
.vjs-control.vjs-record-indicator {
  bottom: 5ex !important;
  top: auto !important;
}
.wrong-permissions {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}
.wrong-permissions img {
  width: 75%;
}
.alt-instructions {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
}
