.logo.center {
  height: 60px;
  margin-top: 60px;
}
.modal-header {
  border-bottom: none;
  align-items: start;
}
.modal-footer {
  border-top: none;
}
.modal-footer.center {
  justify-content: center;
}
.modal-dialog {
  background: #ffffff;
  border: 1px solid #979797;
  box-shadow: 0 0 10px 0 rgba(155, 155, 155, 0.5);
  border-radius: 5px;
}
.modal-title {
  font-family: Dosis;
  font-size: 25px;
  color: #007cff;
  letter-spacing: 0;
  font-weight: bold;
}
img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}
.alfa-list {
  list-style-type: none;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
  -webkit-appearance: none;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input::-ms-clear {
  display: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #777;
}

.dark-toast {
  background: #323232;
  color: #fff;
}

.dark-toast > .toastify__close {
  color: #fff;
}
.transparent-progress {
  background: rgba(255, 255, 255, 0.7);
}
.no-access {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.hselect {
  background-color: white;
}
