.login.window {
  background: white;
  margin-top: 20px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  max-height: calc(100% - 40px);
  overflow: scroll;
  position: relative;
}
.linked.icon {
  position: absolute;
  left: 15px;
  top: 8px;
  font-size: 1.5em;
}

.login.window .footer {
  /* Rectangle 8: */
  background: #ebebeb;
}
.footer {
  height: 35px;
}
.footer > div {
  top: 8px;
  position: relative;
  height: 22px;
}
