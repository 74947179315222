.wizard-line {
  height: 5px;
  background-color: #0980ff;
  display: flex;
  width: 25%;
}
.wizard-bar {
  width: calc(80% - 30px);
  display: flex;
  justify-content: center;
  margin: 44px auto;
}
.wizard-dot {
  position: relative;
  top: -8px;
  cursor: pointer;
}
.wizard-dot .circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #0080ff;
}
.wizard-dot .text {
  font-family: "Dosis";
  color: #0080ff;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  width: 100px;
  text-align: center;
  right: -40px;
}
.wizard-dot img {
  position: relative;
  top: -2px;
  right: -4px;
}
.wizard-dot.inactive .text {
  color: #d8d8d8;
}
.wizard-dot.inactive .circle {
  background-color: #d8d8d8;
}
.wizard-line.inactive {
  background-color: #d8d8d8;
}
