.modal-header.headerPreRecording {
  text-align: -webkit-center;
  display: flow-root;
}
.titleModal {
  font-family: "Dosis";
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #007cff;
}
.txtModal {
  font-family: "sf_compact_display";
  font-size: 16px;
  font-weight: 300;
  line-height: 1.19;
  text-align: center;
  color: #4a4a4a;
  padding-right: 20px;
  padding-left: 20px;
}
.txtSpanModal {
  font-family: "sf_compact_display";
  font-size: 16px;
  font-weight: bold;
  color: #0080ff;
}
.logoModal {
  width: 154px;
  margin-bottom: 28px;
}
.modal-dialog.modal-lg.modalRecording {
  max-width: 450px;
  min-width: 280px;
  border-radius: 10px;
}
.modal-body.modalBodyTxt {
  padding: 0px;
}
