.calibrateSolid {
  margin-top: 0px;
}

.calibrateSolid .bar {
  display: flex;
  flex-direction: row;
  height: 7px;
  margin-top: 10px;

  margin-left: 30px;
  margin-right: 30px;
}
.calibrateSolid .labels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0;
  font-weight: 100;
  margin-top: 10px;
  margin-bottom: 40px;

  margin-left: 30px;
  margin-right: 30px;
}
.calibrateSolid .progress {
  border-radius: 100px;
  display: flex;
  justify-content: flex-end;
  overflow: visible;
}
.calibrateSolid .progress {
  background-image: none;
  background-color: #007cff;
  position: relative;
}
.calibrateSolid .missing {
  background: #ebebeb;
  border-radius: 100px;
}
.calibrateSolid .indicator {
  width: 30px;
  position: absolute;
  right: -15px;
  z-index: 100;
  top: -12px;
  height: 30px;
}
.calibrateSolid .indicatorNumber {
  position: absolute;
  color: #007cff;
  width: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  top: 19px;
  right: -10px;
}
.calibrateSolid .indicator img {
  width: 30px;
}
.calibrateSolid .axis {
  position: relative;
}
.calibrateSolid .titleCompetency {
  font-weight: bold;
  position: relative;
  z-index: 99;

  margin-left: 20px;
  margin-right: 20px;
}
.calibrateSolid .axis span {
  color: #ebebeb;
  position: absolute;
  z-index: 99;
  left: -5px;
  width: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}
.calibrateSolid .axis span.active {
  color: #007cff;
}
.calibrateSolid .axis .line {
  content: "";
  position: absolute;
  border-right-color: #ebebeb;
  border-right-width: 1px;
  border-right-style: solid;
  width: 1px;
  height: 90px;
  display: block;
  right: -0.5px;
  top: -65px;
}
.newVacancy .calibrateSolid:first-child .axis .line {
  height: 164px;
  top: -138px;
}
@media screen and (max-width: 767px) {
  .newVacancy .calibrateSolid:first-child .axis .line {
    top: -65px;
    height: 90px;
  }
  .newVacancy .calibrateSolid:first-child {
    margin-top: 20px;
  }
}

.calibrateSolid .circle {
  position: absolute;
  border-radius: 15px;
  width: 15px;
  background-color: #007cff;
  height: 15px;
  top: -21px;
  left: -7.5px;
}
.calibrateSolid .circle.inactive {
  background-color: #ebebeb;
}

.calibrateSolid .circle.target {
  background-color: #48e1c0;
}
.calibrateSolid .number.target {
  color: #48e1c0;
}
.calibrateSolid .axis span.active.target {
  color: #48e1c0;
}
