.position .react-autosuggest__suggestions-container--open {
  left: 50%;
  transform: translate(-50%, 0);
  top: 39px;
  max-width: 300px;
  width: 100%;
}
.inline-input {
  border: none;
  border-bottom: 1px solid #0080ff;
  color: #0080ff;
  font-weight: bold;
}
.newVacancy .center {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.newVacancy.container {
  width: 80%;
  margin-top: 20px;
  background: white;
  padding-top: 16px;
  padding-bottom: 15px;
  border-radius: 7px;
  margin-right: auto;
  margin-left: auto;
  /*-webkit-box-shadow: 1px 2px 7px -5px rgba(0,0,0,0.64);
    -moz-box-shadow: 1px 2px 7px -5px rgba(0,0,0,0.64);
    box-shadow: 1px 2px 7px -5px rgba(0,0,0,0.64);*/
  box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 0.5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 0.5);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 0.5);
  position: relative;
}

.newVacancy .close {
  top: 10px;
  width: 100px;
  text-align: right;
  position: absolute;
  right: 10px;
  cursor: pointer;
}
.background-modal {
  background-color: #f6f6f6;
}
.newVacancy .headers {
  margin-top:30px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-right: 30px;
  margin-left: 30px;
}
.newVacancy .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;

  color: #ebebeb;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .newVacancy .headers {
    display: none;
  }
}
@media screen and (max-width: 886px) {
  .newVacancy .headers {
    font-size: 14px;
  }
}
.line-center{
  display: inline-flex;
  justify-content: center;
  width: 100%;
}
.header.skill-level{
  color: #0080ff;
}