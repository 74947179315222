.modalKeyQuestion {
  position: relative;
}
.modalKeyQuestion .cancelSimbol {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
}
.cancelVideo {
  width: 30px;
}
