@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* .btn-primary { */
  /*   @apply py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75; */
  /* } */
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  width: 100%;
}
* {
  font-family: "sf_compact_display";
}
*:focus {
  outline: none !important	;
}

body #root {
  height: 100%;
  width: 100%;
}
body #root > div {
  height: 100%;
  width: 100%;
}

.loginBackground {
  height: 100%;
  width: 100%;
  background: url(./images/background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-blue,
.btn-primary {
  background-color: #007cff !important;
}

.bg-blue .btn-dark {
  background-color: #007cff !important;
  border: none !important;
}
.bg-blue .btn-dark:focus {
  box-shadow: none;
}
.bg-gray {
  background-color: #e3e3e3 !important;
  height: 50px;
}

.full-height {
  height: 100%;
}
.full-height-40 {
  max-height: calc(100% - 40px);
}
.white {
  color: white;
}
.blue {
  color: #0072ff;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.font-weight-light {
  font-weight: 100;
}
.font-weight-semi-bold {
  font-weight: 600;
}
.font-smaller {
  font-size: 0.8em;
}
.font-small {
  font-size: 0.9em;
}
.w-20 {
  width: 20%;
}
.w-40 {
  width: 40%;
}
.w-40 {
  width: 40%;
}
.h-1 {
  height: 1px;
}
.clickable {
  cursor: pointer;
}

.blue-text {
  color: #007cff;
}
.btn-plus {
  width: 50px;
  background-size: cover;
  justify-content: center;
  cursor: pointer;
}
.btns-center {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

label.default {
  font-size: 14px;
  color: #4a4a4a;
  letter-spacing: 0;
  font-weight: bold;
}
label.secondary {
  font-size: 14px;
  color: #0080ff;
  letter-spacing: 0;
  font-weight: bold;
}
.max-100 {
  max-width: 100px;
}
.max-300 {
  max-width: 300px;
}
.max-400 {
  max-width: 400px;
}
.circleOptions {
  display: flex;
  max-width: 400px;
  margin: 20px auto;
  justify-content: space-between;
}
.circleOptions.area {
  max-width: 600px;
}
.circleOptions.numbers {
  justify-content: center;
  align-items: center;
}
.circleOption {
  display: flex;
  text-align: center;
  flex-direction: column;
  max-width: 70px;
  align-items: center;
}

.circleSelection {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: inline-block;
  background: #ebebeb;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circleSelection.medium {
  width: 54px;
  height: 54px;
  border-radius: 54px;
}
.circleSingleCharacter {
  letter-spacing: 0;
  color: #e5e5e5;
  font-size: 30px;
  line-height: 40px;
}
.circleSelection.active {
  background: #007cff;
}

.numbers .circleSelection.active {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  line-height: 60px;
}

.numbers .circleSingleCharacter.active {
  color: #ffffff;
  font-size: 40px;
}

.circleText {
  font-weight: 100;
  font-size: 14px;
  color: #4a4a4a;
  letter-spacing: 0;
}

.text {
  font-size: 18px;
  color: #4a4a4a;
  letter-spacing: 0;
}
.grayBox {
  max-width: 360px;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 15px;
  background: #7f7f7f;
  border-radius: 100px;
}
.pinkBox {
  max-width: 360px;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 15px;
  font-weight: 100;
  opacity: 0.5;
  background: #fc131b;
  border-radius: 100px;
  font-weight: 200;
}
.msg {
  font-size: 16px;
  color: #4a4a4a;
  letter-spacing: 0;
  line-height: 19px;
  margin: 0 auto;
  font-weight: 200;
}
.alfa-list li {
  font-size: 14px;
  color: #4a4a4a;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 200;
}

#new.__react_component_tooltip {
  background-color: #fbfbfb;
  opacity: 1;
  color: black;
  box-shadow: 0px 0px 5px 0px rgba(189, 189, 189, 1);
}
#new.__react_component_tooltip.place-right::after {
  border-right-color: #fbfbfb;
}

#new.__react_component_tooltip.place-top::after {
  border-top-color: #fbfbfb;
}

#new.__react_component_tooltip.place-left::after {
  border-left-color: #fbfbfb;
}
#new.__react_component_tooltip.place-bottom::after {
  border-bottom-color: #fbfbfb;
}
.tooltipTitle {
  color: #007cff;
  font-weight: bold;
}

.flex-center {
  display: flex;
  justify-content: center;
}
