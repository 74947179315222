.detail {
  width: 100%;
  background: #fbfbfb;
  padding-bottom: 40px;
}
.detail-title {
  font-weight: bold;
  font-size: 18px;
  color: #0077ff;
  letter-spacing: 0;
  text-align: center;
}
.btns {
  width: 230px;
  border-radius: 50px;
  padding: 15px 25px;
  font-size: 16px;
  text-decoration: none;
  border: none;
  margin: 20px;
  position: relative;
  display: inline-block;
  font-family: "sf_compact_display";
  font-weight: 500;
  text-align: center;
  color: #656565;
  cursor: pointer;
}
.btns:active {
  background-color: #003878;
  color: white;
  font-weight: bold;
}
.blues {
  background-color: #003878;
  color: white;
  /*box-shadow: 0px 5px 0px 0px #3C93D5;*/
}
.blues:hover {
  background-color: #022f63;
  font-weight: bold;
  font-family: "sf_compact_display";
}
.grays {
  background-color: #ebebeb;
}
.grays:hover {
  background-color: #d4d4d4;
}
.detail-tooltip > div {
  font-size: 12px;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  margin: 0 auto;
  width: 230px;
  /*opacity: 0.5;*/
  /*background: #D8D8D8;*/
  background: #efefef;
  border-radius: 100px;
  color: #929292;
}
.detail-competencies {
  display: flex;
  margin-top: 20px;
  width: 68%;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .detail-competencies {
    justify-content: flex-start;
    width: 100%;
    overflow-x: scroll;
  }
}

.detail-competency {
  box-shadow: 0 0 4px 0 rgba(143, 143, 143, 0.5);
  border-radius: 5px;
  font-family: "sf_compact_display";
  min-width: 116px;
  max-width: 116px;

  height: 158px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-left: 23px;
  padding-right: 23px;
  padding-bottom: 10px;
  margin: 2px;
}
.btnComparative.inactive {
  width: 204px;
  border-radius: 100px;
  border: none;
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: #afafaf;
  background: #f6f6f6;
  margin: 5px;
  font-family: "sf_compact_display";
  position: relative;
  display: inline-block;
}
.starDetailCircle {
  padding: 2px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: #50e3c2;
  margin-right: 5px;
}
.starCompetency {
  padding: 2px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: #50e3c2;
  margin-left: 38px;
  margin-top: 8px;
  position: absolute;
}
.btnComparative.active {
  width: 204px;
  background-image: linear-gradient(0deg, #001fff 0%, #0080ff 100%);
  box-shadow: 0 0 4px 0 rgba(83, 83, 83, 0.5);
  letter-spacing: 0;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 8px;
  border-radius: 100px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: #ffffff;
  margin: 5px;
  font-family: "sf_compact_display";
  position: relative;
  display: inline-block;
}
.detail-icon {
  background: #377bff;
  background-repeat: no-repeat;
  border-radius: 60px;
  width: 50px;
  height: 50px;
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}
.detail-icon img {
  background-size: cover;
  width: 32px;
  height: 32px;
}
.detail-name {
  font-weight: 200;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0;
  text-align: center;
  top: 8px;
  position: relative;
  line-height: 12px;
}
.detail-evaluation {
  font-weight: bold;
  font-family: Dosis;
  font-size: 30px;
  color: #007cff;
  letter-spacing: 0;
  position: absolute;
  bottom: 15px;
}
.detail-evaluation .separator {
  color: #ebebeb;
}
.detail-evaluation .target {
  color: #48e1c0;
  font-family: Dosis;
}
.detail-score {
  width: 100%;
  text-align: center;
  background: #e9e9e9;
  opacity: 0.5;
  font-size: 10px;
  color: #000000;
  letter-spacing: 0;
  position: absolute;
  bottom: 0px;
}
.comparative {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.comparative .inactive {
  background: #ebebeb;
  border-radius: 100px;
  width: 204px;
  height: 49px;
  opacity: 0.3;
  font-family: SFCompactDisplay-Medium;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 20px;
  padding-left: 20px;
}
.comparative .active {
  background-image: linear-gradient(0deg, #001fff 0%, #0080ff 100%);
  box-shadow: 0 0 4px 0 rgba(83, 83, 83, 0.5);
  border-radius: 100px;
  width: 204px;
  height: 49px;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 20px;
  padding-left: 20px;
}
.skillsPurple {
  width: 91%;
  height: 40px;
  padding-right: 10px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 40px;
}
.Oval-3 {
  width: 43px;
  height: 43px;
  border: solid 1px #007cff;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  float: left;
  margin-top: 3px;
}
.shapeD {
  width: 13px;
  height: 13px;
  margin-top: 10px;
  margin-left: 8px;
}
.lsPurple {
  width: 26px;
  height: 20px;
  object-fit: contain;
  margin-top: 10px;
  margin-left: 8px;
}
.lsOrange {
  width: 20px;
  height: 26px;
  object-fit: contain;
  margin-top: 8px;
  margin-left: 10px;
}
.lsPink {
  width: 19px;
  height: 26px;
  object-fit: contain;
  margin-top: 7px;
  margin-left: 11px;
}
.lsBlue {
  width: 27px;
  height: 26px;
  object-fit: contain;
  margin-top: 6px;
  margin-left: 7px;
}
.labelPurple {
  padding: 0px 0px 5px 5px;
  float: left;
  z-index: 1;
  margin-left: 1px;
  margin-right: 78%;
}
.labelOrange {
  padding: 0px 0px 5px 5px;
  float: left;
  z-index: 1;
  margin-left: 1px;
  margin-right: 65%;
}
.labelPink {
  padding: 0px 0px 5px 5px;
  float: left;
  z-index: 1;
  margin-left: 1px;
  margin-right: 71%;
}
.labelBlue {
  padding: 0px 0px 5px 5px;
  float: left;
  z-index: 1;
  margin-left: 1px;
  margin-right: 62%;
}
.labelPurplePorciento {
  padding: 0px 0px 5px 5px;
  float: right;
  z-index: 1;
  margin-left: 76%;
  width: 40px;
  font-weight: 600;
}
.barUnder {
  width: 84%;
  height: 7px;
  border-radius: 100px;
  background-color: #ebebeb;
  z-index: 0;
  margin-left: 50px;
  position: absolute;
  /*margin-top: 35px;*/
}
.barPurple {
  width: 75%;
  height: 7px;
  border-radius: 100px;
  background-color: #9012fe;
  float: left;
  z-index: 2;
  position: absolute;
  margin-left: 50px;
  /*margin-top: 35px;*/
}
.barOrange {
  width: 33%;
  height: 7px;
  border-radius: 100px;
  background-color: #ffbd00;
  float: left;
  z-index: 2;
  position: absolute;
  margin-left: 50px;
  /*margin-top: 35px;*/
}
.barPink {
  width: 15%;
  height: 7px;
  border-radius: 100px;
  background-color: #ff0d70;
  float: left;
  z-index: 2;
  position: absolute;
  margin-left: 50px;
  /*margin-top: 35px;*/
}
.barBlue {
  width: 84%;
  height: 7px;
  border-radius: 100px;
  background-color: #0077ff;
  float: left;
  z-index: 2;
  position: absolute;
  margin-left: 50px;
  /*margin-top: 35px;*/
}
.barDivisora {
  width: 92%;
  margin-top: 3%;
}
.psycReport {
  width: 95%;
  background: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  padding-top: 10px;
  border: 1px solid #fafafa;
  border-radius: 7px;
  box-shadow: 0px 0px 3px 0px rgba(179, 179, 179, 0.5);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(179, 179, 179, 0.5);
  -moz-box-shadow: 0px 0px 3px 0px rgba(179, 179, 179, 0.5);
}
.onlinePop {
  width: 95%;
  background: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  padding-top: 10px;
  padding-bottom: 40px;
  border: 1px solid #fafafa;
  border-radius: 7px;
  box-shadow: 0px 0px 3px 0px rgba(179, 179, 179, 0.5);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(179, 179, 179, 0.5);
  -moz-box-shadow: 0px 0px 3px 0px rgba(179, 179, 179, 0.5);
}
.onlineNews {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}
.newsCard {
  padding-top: 50px;
}
.tagBtn {
  color: #4a4a4a;
  background-color: #f4f4f4;
  border-radius: 55px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  border: 0px;
}
.tagBtn:link {
  color: #4a4a4a;
  background-color: #f4f4f4;
  border-radius: 55px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  border: 0px;
}
.tagBtn:active {
  color: #ffffff;
  background-color: #007bff;
  border-color: #007bff;
  border-radius: 55px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
}
.tagBtn:hover {
  color: #ffffff;
  background-color: #007bff;
  border-color: #007bff;
  border-radius: 55px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
}
.spaceCard {
  margin-right: 10px;
  margin-top: 10px;
}
.contRowTags {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.onlineCloud {
  width: 95%;
  background: white;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  padding-top: 10px;
  padding-bottom: 40px;
  border: 1px solid #fafafa;
  border-radius: 7px;
  box-shadow: 0px 0px 3px 0px rgba(179, 179, 179, 0.5);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(179, 179, 179, 0.5);
  -moz-box-shadow: 0px 0px 3px 0px rgba(179, 179, 179, 0.5);
}
.onSent {
  width: 95%;
  background: white;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  padding-top: 10px;
  border: 1px solid #fafafa;
  border-radius: 7px;
  box-shadow: 0px 0px 3px 0px rgba(179, 179, 179, 0.5);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(179, 179, 179, 0.5);
  -moz-box-shadow: 0px 0px 3px 0px rgba(179, 179, 179, 0.5);
}
.circleRow {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  float: left;
}
.candidatePic {
  width: 95%;
  background: white;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #fafafa;
  border-radius: 7px;
  box-shadow: 0px 0px 3px 0px rgba(179, 179, 179, 0.5);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(179, 179, 179, 0.5);
  -moz-box-shadow: 0px 0px 3px 0px rgba(179, 179, 179, 0.5);
}
.rowChart {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.rowCand {
  width: 73%;
  margin-right: auto;
  margin-left: auto;
}
.chartLeft {
  width: 146px;
  height: 222px;
  margin-left: 120px;
  float: left;
}
.chartRight {
  width: 146px;
  height: 222px;
  margin-left: 55px;
  float: left;
}
.chartText {
  margin-top: 180px;
  text-align: center;
  font-size: 21px;
  font-weight: 500;
}
.wordCloud {
  width: 80%;
  height: 40%;
  margin-left: auto;
  margin-right: auto;
}
.buttonsReport {
  width: 73%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 120px;
}
.reportButtonActive {
  color: white;
  width: 168px;
  background: #003878;
  padding-top: 14px;
  padding-bottom: 16px;
  text-align: center;
  border-radius: 30px;
  font-weight: bold;
  margin-top: 29px;
  margin-right: 14px;
  float: left;
  font-size: 16px;
  box-shadow: 0px 0px 4px 0px rgba(83, 83, 83, 0.5);
  -webkit-box-shadow: 0px 0px 4px 0px rgba(83, 83, 83, 0.5);
  -moz-box-shadow: 0px 0px 4px 0px rgba(83, 83, 83, 0.5);
  border: 0px;
}
.reportButton {
  color: #8a8a8a;
  width: 168px;
  font-size: 16px;
  background: #ebebeb;
  padding-top: 14px;
  padding-bottom: 16px;
  text-align: center;
  border-radius: 30px;
  font-weight: 500;
  margin-top: 29px;
  margin-right: 14px;
  float: left;
  border: 0px;
}
.reportTable {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 500px;
  margin-top: 25px;
}
.graph {
  width: 50%;
  float: left;
  height: 324px;
}
/*.harcG{
	width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px;
}*/
.contenido {
  height: 325px;
  width: 100%;
  margin: 5px;
}
.boxArc {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.graph2 {
  width: 270px;
  float: left;
  height: 250px;
  padding-left: 11%;
  margin-right: 8%;
}
.textChart {
  z-index: 1;
  position: absolute;
  font-size: 14px;
  text-align: center;
  margin-left: 73px;
  margin-right: auto;
  margin-top: 160px;
}
.numberChart {
  z-index: 1;
  position: absolute;
  font-size: 100px;
  text-align: center;
  margin-left: 63px;
  margin-right: auto;
  margin-top: 40px;
}
.circleGray1 {
  width: 295px;
  height: 295px;
  background: #e0e0e0;
  border-radius: 150px;
  margin-left: 30px;
  margin-right: auto;
  margin-top: 15px;
  z-index: 1;
  position: absolute;
}
.circleGray2 {
  width: 250px;
  height: 250px;
  background: red;
  border-radius: 150px;
  margin-left: 52px;
  margin-right: auto;
  margin-top: 38px;
  border: 8px solid white;
  z-index: 1;
  position: absolute;
}
.tableGraphic {
  width: 255px;
  height: 324px;
  float: left;
  background: #003878;
  border-radius: 6px;
  color: #ffffff;
  font-size: 14px;
  line-height: 1.4;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
}
.conteni2 {
  justify-content: center;
  align-content: center;
}
.conteni3 {
  background: #003878;
  height: 100%;
  width: 265px;
  padding: 5px;
  border-radius: 10px;
}
.tableTitle {
  font-size: 16px;
  font-weight: bold;
}
.tableMentions {
  width: 280px;
  float: left;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 300px;
  color: #4a4a4a;
  line-height: 1.88;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
}
.rowPsycoReport {
  margin-bottom: 0px;
  border-bottom: 1px solid rgba(234, 234, 234, 0.5);
  height: 34px;
  padding-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}
.nameReport {
  color: #ffffff;
  font-size: 14px;
  padding-left: 5px;
}
.porcentageReport {
  color: #ffffff;
  font-size: 14px;
  position: relative;
  float: right;
  padding-right: 5px;
}
.diagonal {
  float: right;
  position: relative;
  padding-top: 5px;
  cursor: pointer;
}
.rowTable {
  border-bottom: 1px solid #5c819e;
}
.cellRight {
  width: 66px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.64;
  text-align: right;
  color: #ffffff;
  padding-right: 15px;
}
.cellLeft {
  width: 210px;
  padding-left: 15px;
}
.psyFeedback {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #0a84ff;
  border: 1px solid #0a84ff;
  border-radius: 31px;
  padding-top: 10px;
  padding-bottom: 9px;
  margin-top: 58px;
  margin-bottom: 43px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
.buttonPsyFeed {
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}
.boxGraphMentions {
  width: 203px;
  margin-left: auto;
  margin-right: auto;
  z-index: 0;
}
.txtNewMentions {
  position: absolute;
  text-align: center;
  padding-top: 43%;
  padding-left: 51px;
}
.squareNews {
  width: 204px;
  height: 171px;
  background: blue;
  border-radius: 8px;
  float: left;
  margin-right: 10px;
}
.squareCandidate {
  width: 132px;
  height: 171px;
  background: blue;
  border-radius: 8px;
  float: left;
  margin-right: 10px;
}
.card {
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  width: 204px;
  border-radius: 6px;
  box-shadow: 0px 0px 3px 0px rgba(179, 179, 179, 0.5);
  -webkit-box-shadow: 0px 0px 3px 0px rgba(179, 179, 179, 0.5);
  -moz-box-shadow: 0px 0px 3px 0px rgba(179, 179, 179, 0.5);
}
.cardBig {
  height: 171px;
  min-height: 171px;
}
.cardImage {
  position: absolute;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repat;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
}
.cardImages {
  position: absolute;
  background-size: cover;
  background-position: center bottom;
  width: 100%;
  height: 62%;
}
.sentimentActiv {
}
.card2 {
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  width: 132px;
  margin-right: 72px;
  float: left;
  border-radius: 6px;
  -webkit-box-shadow: 2px 4px 4px -4px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 2px 4px 4px -4px rgba(0, 0, 0, 0.49);
  box-shadow: 2px 4px 4px -4px rgba(0, 0, 0, 0.49);
}
.card3 {
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  width: 132px;
  float: left;
  border-radius: 6px;
  -webkit-box-shadow: 2px 4px 4px -4px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 2px 4px 4px -4px rgba(0, 0, 0, 0.49);
  box-shadow: 2px 4px 4px -4px rgba(0, 0, 0, 0.49);
}
.cardBig2 {
  height: 171px;
  min-height: 171px;
}
.cardImage2 {
  position: absolute;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repat;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
}
.cardImages2 {
  position: absolute;
  background-size: cover;
  background-position: center bottom;
  width: 100%;
}
.cardText {
  user-select: none;
  font-size: 14px;
  font-weight: 500;
  display: block;
  left: 0;
  right: 0;
  top: 100px;
  padding: 16px;
  margin: 0;
  line-height: 1.2;
  position: absolute;
  color: #000000;
  overflow: hidden;
  transition: width 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: delay 0.1s;
}
.cardTextMin {
  font-size: 10px;
  opacity: 0.5;
  user-select: none;
  display: block;
  left: 0;
  right: 0;
  top: 135px;
  padding: 16px;
  margin: 0;
  line-height: 1.6;
  position: absolute;
  color: #000000;
  overflow: hidden;
  transition: width 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: delay 0.1s;
}
.cardCan {
  width: 132px;
  height: 171px;
  background: blue;
  border-radius: 5%;
  float: left;
  margin-right: 78px;
}
.cardCan1 {
  width: 132px;
  height: 171px;
  background: blue;
  border-radius: 6px;
  float: left;
}
.textStart {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
}
.reduceRow {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}
.cardCans {
  border-radius: 30%;
}
.boxCard {
  width: 132px;
  float: left;
  margin-right: 72px;
}
.boxCard1 {
  width: 132px;
  float: left;
}
.wordActive {
  padding: 5px 14px 5px 14px;
  margin-right: 4px;
  background: #0077ff;
  border-radius: 50px;
  float: left;
  color: white;
}
.wordPause {
  padding: 5px 14px 5px 14px;
  margin-right: 4px;
  background: #f4f4f4;
  border-radius: 50px;
  float: left;
  color: #4a4a4a;
}
.row1 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: 55px;
}
.row2 {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  height: 55px;
}
.row3 {
  width: 78%;
  margin-left: auto;
  margin-right: auto;
  height: 55px;
}
.showMore {
  width: 321px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 40px;
  color: #0077ff;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}
.textSend {
  margin-left: auto;
  margin-right: auto;
  margin-top: 58px;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  color: white;
  padding-bottom: 10px;
  padding-top: 6px;
  width: 293px;
  height: 41px;
  border-radius: 5px;
  background-color: #0080ff;
  border: solid 1px #0077ff;
}
.detail-competency .CircularProgressbar .CircularProgressbar-path {
  stroke: #3366cc;
  stroke-linecap: round;
}
.CircularProgressbar-path .red {
  stroke: red;
  stroke-linecap: round;
}
.CircularProgressbar-trail {
  /*stroke: #f4f4f4;*/
  fill-opacity: inherit;
}
.CircularProgressbar-text {
  fill: #0077ff;
  font-size: 31px;
  font-weight: 600;
  text-anchor: middle;
  dominant-baseline: middle;
}
.CircularProgressbar {
  float: left;
  fill: #0077ff;
}
.progressbar-blue .CircularProgressbar-path {
  stroke: #0077ff;
}
.progressbar-blue .CircularProgressbar-text {
  fill: #0077ff;
  font-size: 31px;
  font-weight: 600;
  text-anchor: middle;
  dominant-baseline: middle;
}
.progressbar-pink .CircularProgressbar-path {
  stroke: #ff0d70;
}
.progressbar-pink .CircularProgressbar-text {
  fill: #ff0d70;
  font-size: 31px;
  font-weight: 600;
  text-anchor: middle;
  dominant-baseline: middle;
}
.textNews {
  float: left;
  z-index: 1;
  position: absolute;
  margin-top: 19%;
  margin-left: 7%;
  font-size: 14px;
}
/* PROGRESSBAR /////////////////////*/
.candidatos {
  position: relative;
  height: 40px;
  margin: 10px 20px;
  padding-left: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 65px;
}
.candidatos.withoutImg {
  padding-left: 0px;
}
/*.candidatos img {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  border: 1px solid #007cff;
  padding: 7px;
}*/
.imgCandidate {
  padding: 7px;
  border: 1px solid #007cff;
  border-radius: 50%;
  width: 43px;
  height: 43px;
}
.candidatos .parcial {
  display: inline-block;
  width: 100%;
  height: 40px;
  vertical-align: middle;
  padding: 0 0 0 10px;
}
.candidatos .parcial .info {
  height: 15px;
  position: relative;
}
.candidatos .parcial .info > div {
  /*float: left;*/
}
.candidatos .parcial .info .nome {
  /*position: absolute;
  top: 0;
  left: 0;*/
  font-size: 15px;
  font-weight: 600;
  color: #192d49;
}
.candidatos .parcial .info .eleito,
.candidatos .parcial .info .segundo-turno {
  color: #fff;
  padding: 0 5px 2px 5px;
  border-radius: 2px;
}
.candidatos .parcial .info .percentagem-num {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  font-weight: bold;
  color: #4a4a4a;
}
.candidatos .parcial .progressBarSki {
  position: relative;
  width: 100%;
  height: 7px;
  margin: 10px 0 2px;
  border-radius: 10px;
  background: #e7e7e7;
}
.candidatos .parcial .progressBarSki .percentagem {
  position: absolute;
  top: 0;
  left: 0;
  height: 7px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: #a4b5c0;
  -webkit-transition: 2s all;
  -webkit-animation-duration: 2s;
  -webkit-animation-name: animationProgress;
}
.candidatos.leaderPurple .parcial .percentagem {
  background-color: #9012fe;
}
.candidatos.orientationOrange .parcial .percentagem {
  background-color: #ffbd00;
}
.candidatos.thinkPink .parcial .percentagem {
  background-color: #ff0d70;
}
.candidatos.teamBlues .parcial .percentagem {
  background-color: #0077ff;
}
.candidatos .parcial .partidas {
  font-family: Arial, helvetica, Sans-serif;
  font-size: 10px;
  font-weight: 600;
  color: #9e9c9b;
  text-transform: uppercase;
}
.percentual {
  -webkit-transition: 5s all;
  -webkit-animation-duration: 5s;
  -webkit-animation-name: animationProgress;
  transition: 5s all;
  animation-duration: 5s;
  animation-name: animationProgress;
}
@-webkit-keyframes animationProgress {
  from {
    width: 0;
  }
}
@keyframes animationProgress {
  from {
    width: 0;
  }
}
.percentagem-num {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  font-weight: bold;
  color: #192d49;
}
.skillsCandidate {
  position: absolute;
  font-size: 16px;
  text-align: left;
  color: #4a4a4a;
}
.detailC {
  display: flex;
  flex-direction: column;
}
.detailC .row {
  margin-top: 50px;
}
.contenedor {
  margin-top: 50px;
}
.overflowElement {
  flex-wrap: wrap;
  white-space: nowrap;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  justify-content: center;
  display: flex;
}
.overflowDetail {
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
}
.overflowComparative {
  overflow-x: auto;
  cursor: pointer;
}
.rowComparative {
}
::-webkit-scrollbar {
  width: 15px;
}
@mediakit: : -webkit-scrollbar-track{
  background-color: white;
};

.psychoColumn {
  flex-basis: 32%;
}
.psychoColumnFull {
  flex-basis: 95%;
}

.psychoContainer {
  display: flex;
  flex-direction: row;
}

.graph-candidates .headers {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-right: 53px;
  margin-left: 53px;
  margin-bottom: 10px;
}
.graph-candidates .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;

  color: #ebebeb;
  font-weight: bold;
}
.detail-competency {
  position: relative;
}
.detail-competency .CircularProgressbar.primary {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 5px;
}
.detail-competency .CircularProgressbar.secondary {
  position: absolute;
  width: 70px;
  height: 70px;
  top: 0px;
  fill: transparent;
}
.detail-icon {
  position: relative;
  z-index: 0;
}
.CircularProgressbar .CircularProgressbar-trail {
  stroke: #ebebeb !important;
}
.detail-competency .CircularProgressbar.secondary .CircularProgressbar-path {
}
.detail-competency .CircularProgressbar.primary .CircularProgressbar-path {
}
.flags {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.pill {
  cursor: default;
  background-color: red;
  color: white;
  font-weight: bold;
  font-size: 12px;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 10px;
  margin-left: 10px;
}
.sentiment_graph img{
  width: 500px;
  max-width: 100%;
}
.sentiment_graph {
  display: flex;
  justify-content: center;
  background-color: white;
}
