.toggle {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.toggle .toggle-label {
  font-size: 16px;
  color: #4a4a4a;
  letter-spacing: 0;
  line-height: 56px;
  font-weight: 100;
}
.toggle-check {
  cursor: pointer;
}
