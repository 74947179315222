.vacancies {
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: calc(100% - 105px);
  color: #9b9b9b;
}
.vacancies.vertical-center {
  align-items: center;
}
.vacancies.vertical-center > div {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.vacancies.center {
  flex: 1;
  display: flex;
  align-items: center;
}
.vacancies .list {
  min-width: 300px;
  width: 80%;
}
.vacancies .watermark {
  width: 50;
  margin: 0 auto;
}
.vacancies .watermark-text {
  font-size: 18px;
  font-weight: 200;
  text-align: center;
}
.vacancies .add-btn {
  border: 1px solid #0077ff;
  border-radius: 5px;
  color: #007cff;
  padding: 2px 10px;
  font-size: 18px;
  margin-top: 20px;
  cursor: pointer;
}
.vacancies .add-btn .icon-plus {
  width: 22px;
  margin-right: 15px;
}
.vacancies .box {
  align-items: center;
  background: #ffffff;
  box-shadow: 0 0 10px 0 rgba(155, 155, 155, 0.5);
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 20px 20px;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  height: 140px;
}
.vacancies .box .company {
  color: #4a4a4a;
  font-size: 18px;
  font-weight: 600;
}
.vacancies .box .position {
  font-size: 14px;
  color: #4a4a4a;
  font-weight: 200;
}
.vacancies .box .city {
  font-size: 12px;
  color: #9b9b9b;
}
.vacancies .box .remain {
  margin-top: 10px;
  color: #007cff;
  font-size: 14px;
}
.vacancies .box .remain .days {
  font-weight: bold;
}

.vacancies .box .image {
  position: absolute;
  right: 15px;
}
.vacancies .box .image img {
  height: 100px;
  width: 100px;
  object-fit: contain;
}
.searchContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
@media screen and (max-width: 768px) {
  .searchContainer {
    flex-direction: column;
  }
  .searchContainerArchived {
    width: 100%;
    justify-content: center;
  }
}
.searchContainerArchived {
  display: flex;
  align-items: flex-end;
}
.showArchived-text {
  position: relative;
  top: 8px;
  margin-left: 10px;
}
.showArchived {
  top: 5px;
  position: relative;
  margin-left: 10px;
  cursor: pointer;
}
