.checkbox {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.checkbox img {
  margin-right: 10px;
}
.checkbox div {
  margin-right: 10px;
}
