.inviteContainer {
  width: 100%;
  background: #f6f6f6;
  padding-top: 18px;
  padding-bottom: 40px;
}
.inviteAvailable {
  background: #e9e9e9;
  height: 33px;
  font-size: 14px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 197px;
  border-radius: 7px;
  margin-bottom: 29px;
}
.inContainer {
  width: 80%;
  background: white;
  padding-top: 26px;
  padding-bottom: 25px;
  border-radius: 7px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 23px;
  box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 0.5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 0.5);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 0.5);
}
.inTitlePrincipal {
  width: 80%;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  color: #007cff;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 11px;
}
.inSubTitle {
  width: 80%;
  font-size: 16px;
  text-align: center;
  color: #4a4a4a;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
}
.noRule {
  border-right: 0px;
}
.noRules {
  background: white;
}
.editGroup {
  margin-bottom: 10px;
}
.candidateInfo {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.fromCandidate {
}
.txtCandidate {
  margin-bottom: 9px;
  width: 273px;
  height: 19px;
  font-size: 16px;
  text-align: left;
  color: #4a4a4a;
}
.InfoActive {
  width: 100%;
  border-radius: 5px;
  border: solid 1px #0080ff;
  color: #0080ff;
  padding-left: 10px;
  margin-bottom: 10px;
}
.InfoPause {
  width: 100%;
  border-radius: 5px;
  border: solid 1px #9b9b9b;
  color: #9b9b9b;
  padding-left: 10px;
  margin-bottom: 10px;
}
.infoLine {
  width: 75%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 33px;
  margin-bottom: 24px;
}
.addCandidate {
  width: 168px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.addButton {
  width: 140px;
  height: 35px;
  border-radius: 100px;
  border: 0px;
  background-color: white;
  color: #0a84ff;
  font-size: 14px;
}
.addLabel {
  width: 100%;
  padding-left: 10px;
  border-radius: 100px;
  border: solid 1px #0a84ff;
}
.underButton {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  text-align: center;
  color: white;
  padding-bottom: 10px;
  padding-top: 6px;
  width: 293px;
  height: 41px;
  border-radius: 5px;
  background-color: #0080ff;
  border: solid 1px #0080ff;
}
.underButton:hover {
  background-color: #056ace;
}
.unButton {
  width: 100%;
  border: 0px;
  background-color: #0080ff;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
}
.unButton:hover {
  background-color: #056ace;
}
/*.rowUnder{
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 28px;
}
.colUnder{
	width: 40%;
	float: left;
}*/
.txtUnder {
  width: 248px;
  height: 19px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #9b9b9b;
  margin-right: auto;
  margin-left: auto;
}
.selectedClass {
  background: blue;
  color: white;
  height: 20px;
}
.ReactTags__tag {
  border-radius: 19px;
  border: solid 1px #0080ff;
  background: #0080ff;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 4px;
  margin-right: 10px;
  margin-bottom: 3px;
  float: left;
}
.ReactTags__tags {
  position: relative;
}
.ReactTags__selected {
  display: block;
}
.ReactTags__tagInput {
  display: inline-block;
  height: 20px;
}
.ReactTags__tagInputField {
  border: 0px;
  padding-top: 4px;
  padding-bottom: 6px;
  height: 29px;
}
.form-controlEs {
  display: block;
  width: 100%;
  font-size: 1rem;
  line-height: 1.25;
  color: rgb(73, 80, 87);
  background-color: rgb(255, 255, 255);
  background-image: none;
  background-clip: padding-box;
  padding: 0.4rem 0.75rem;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.contRow {
  width: 77%;
  margin-right: auto;
  margin-left: auto;
}
.cancelCandidate {
  position: relative;
  float: right;
  width: 12px;
  cursor: pointer;
}
.emailBatchInput {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  left: 0px;
  right: 0px;
  width: 100%;
  top: 0px;
  height: 100%;
}
.reviewButton.emailBatchButton {
  position: relative;
  padding-top: 5px;
  height: 35px;
  margin-right: 10px;
}
.reviewButton.emailButton {
  max-width: 293px;
  width: 100%;
}
