.calibrate {
  margin-top: 10px;
}
.calibrate .bar {
  display: flex;
  flex-direction: row;
  height: 7px;
  margin-top: 10px;
}
.calibrate .labels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  color: #4a4a4a;
  letter-spacing: 0;
  font-weight: 100;
  margin-top: 10px;
}
.calibrate .progress {
  background-image: linear-gradient(90deg, #007cff 2%, #9dcdff 100%);
  border-radius: 100px;
  display: flex;
  justify-content: flex-end;
  overflow: visible;
}
.calibrate .progress.solid {
  background-image: none;
  background-color: #007cff;
}
.calibrate .missing {
  background: #ebebeb;
  border-radius: 100px;
}
.calibrate .indicator {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 20px;
  position: relative;
  left: 5px;
}
.calibrate .indicator img {
  width: 30px;
}
