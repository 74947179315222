.afg {
  background-image: url("../../images/payment/flags/afganistanFlag.svg")
    no-repeat;
}
.and {
  background-image: url("../../images/payment/flags/andorraFlag.svg") no-repeat;
}
.ago {
  background-image: url("../../images/payment/flags/angolaFlag.svg") no-repeat;
}
