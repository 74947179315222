.fileInput {
  cursor: pointer;
  width: 124px;
  height: 124px;
  position: absolute;
  opacity: 0;
}
.imgPreview {
  text-align: center;
  height: 124px;
  width: 124px;
  border-radius: 10px;
}
.imgPreview img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
