.canContainer {
  width: 100%;
  background: #f6f6f6;
  padding-bottom: 40px;
}
.canZoneTitle {
  width: 100%;
  background: #e3e3e3;
  height: 50px;
}
.canTitle {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 25px;
  padding-top: 11px;
  color: #0077ff;
  letter-spacing: 0;
  text-align: center;
}
.techDirect {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.titleHeader {
  color: #007cff;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}
.subtitleHeader {
  margin-left: auto;
  margin-right: auto;
  font-size: 15px;
  text-align: center;
  color: #5f5f5f;
  line-height: 16px;
}
.contCalendar {
  background: #007cff;
  border-radius: 100px;
  width: 50px;
  /* margin-left: 40%; */
  /* margin-top: 5%; */
  cursor: pointer;
  display: inline-block;
  height: 50px;
}
.contCalendar.toggle {
  background-color: transparent;
  margin-top: 24px;
}
.contCalendar.edit {
  margin-right: 20px;
}
.toggle .calendarBlue {
  padding: 0px;
}
.calendarBlue {
  padding: 15px;
  width: 50px;
}
.rowCont {
  margin-left: auto;
  margin-bottom: 15px;
  padding-top: 10px;
}
.canContPrincipal {
  width: 80%;
  background: white;
  padding-top: 26px;
  padding-bottom: 25px;
  border-radius: 7px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 35px;
  box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 0.5);
  -moz-box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 0.5);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(155, 155, 155, 0.5);
}
.barDivisora {
  width: 92%;
  margin-top: 3%;
}
.candidatos {
  width: 100%;
  position: relative;
  height: 40px;
}
.candidatosDos {
  width: 100%;
  position: relative;
  height: 40px;
}
.candidatosDos img {
  position: absolute;
  top: 0;
  left: 0;
  width: 43.6px;
  height: 43.6px;
  border-radius: 50%;
}
.imgCandidatos {
  position: absolute;
  top: 0;
  left: 0;
  width: 43.6px;
  height: 43.6px;
  border-radius: 50%;
}
.parcial.parcialStar {
  padding-left: 50px;
  margin-left: 10px;
}
.parStarCircle {
  padding: 2px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: #50e3c2;
  margin-right: 5px;
  margin-left: 70px;
  margin-top: 26px;
  position: absolute;
}
.btnReview {
  position: absolute;
  padding-top: 18px;
  text-decoration: underline;
  right: 75px;
}
.vistaRev {
  right: 45px;
  position: absolute;
  top: 16px;
  font-family: "sf_compact_display";
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #0080ff;
  cursor: pointer;
}

@media screen and (max-width: 414px) {
  .vistaRev {
    display: none;
  }
}

.blockRev {
  right: -40px;
  position: absolute;
  top: 16px;
  width: 26px;
  height: 26px;
  z-index: 1;
}
.selectCircle {
  width: 26px;
  height: 26px;
  background: white;
  border-radius: 50px;
  border: 1px solid #979797;
  cursor: pointer;
  right: 8px;
  position: absolute;
  top: 16px;
}
.selectCircle.selectBlue {
  background: #0080ff;
  border: 1px solid #0080ff;
}
.candidatos .parcial {
  display: inline-block;
  width: 100%;
  height: 40px;
  vertical-align: middle;
  padding: 0 0 0 10px;
  cursor: pointer;
}
.candidatos .parcial .info {
  height: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.candidatos .parcial .info > div {
  /*float: left;*/
}
.candidatos .parcial .info .nome {
  /*position: absolute;
  top: 0;
  left: 0;*/
  font-size: 16px;
  font-weight: 600;
  color: #192d49;
}
.candidatos .parcial .info .eleito,
.candidatos .parcial .info .segundo-turno {
  color: #fff;
  padding: 0 5px 2px 5px;
  border-radius: 2px;
}
.candidatos .parcial .info .percentagem-num {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  font-weight: bold;
  color: #4a4a4a;
}
.candidatos .parcial .progressBar {
  position: relative;
  width: 100%;
  height: 7px;
  margin: 10px 0 2px;
  border-radius: 10px;
  background: #e7e7e7;
}
.candidatos .parcial .progressBar .percentagem {
  position: absolute;
  top: 0;
  left: 0;
  height: 7px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-image: linear-gradient(to left, #001fff, #0080ff);
  -webkit-transition: 5s all;
  -webkit-animation-duration: 5s;
  -webkit-animation-name: animationProgress;
}

.candidatos .parcial .nameCan {
  font-family: Arial, helvetica, Sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #616161;
}
.percentual {
  -webkit-transition: 5s all;
  -webkit-animation-duration: 5s;
  -webkit-animation-name: animationProgress;
  transition: 5s all;
  animation-duration: 5s;
  animation-name: animationProgress;
}
@-webkit-keyframes animationProgress {
  from {
    width: 0;
  }
}
@keyframes animationProgress {
  from {
    width: 0;
  }
}
.reviewButton {
  width: 293px;
  margin-right: auto;
  margin-left: auto;
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
  cursor: pointer;
}
.row.rowTitle {
  margin-top: 0px;
  padding-top: 24px;
  padding-bottom: 30px;
}
.nomeScore {
  width: 71px;
  height: 20px;
  font-family: "Dosis";
  font-size: 16px;
  font-weight: bold;
  color: #007cff;
}
.nameScore {
  width: 50%;
  font-family: "sf_compact_display";
  font-size: 16px;
  font-weight: 500;
  color: #000000;
}
.reviewScore {
  width: 26px;
  height: 26px;
  background: white;
  border-radius: 50px;
  float: left;
  border: 1px solid #979797;
  cursor: pointer;
}
.textReviewScore {
  float: left;
  padding-right: 18px;
  padding-left: 30px;
  text-decoration: underline;
  color: #0080ff;
  font-size: 16px;
  font-weight: 500;
  font-family: sf_compact_display;
  position: relative;
  cursor: pointer;
}
.search {
  width: 320px;
  margin-bottom: 20px;
  background: transparent;
  border-right: none;
  border-left: none;
  border-top: none;
  border-radius: 0px;
}
.searchContainer {
  display: flex;
  justify-content: center;
}
.admin-btns {
  display: flex;
  justify-content: flex-end;
}
