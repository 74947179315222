.titlePayment {
  font-family: "Dosis";
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  color: #007cff;
  padding-top: 15px;
}

.txtLoad {
  font-family: "Dosis";
  font-size: 15px;
  font-weight: 600;
  line-height: 1.13;
  text-align: center;
  color: #007cff;
  padding-top: 22px;
}

.titlePaySuccess {
  font-family: "Dosis";
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #007cff;
  text-align: center;
  padding-top: 16px;
}
.titlePayError {
  font-family: "Dosis";
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #ff1c72;
  text-align: center;
  padding-top: 16px;
}
.textPaySuccess {
  font-family: "sf_compact_display";
  font-size: 16px;
  font-weight: 300;
  line-height: 1.13;
  text-align: center;
  color: #4a4a4a;
  padding-top: 4px;
  padding-bottom: 18px;
}
.max-300.continuePayment {
  width: 207.3px;
  height: 41px;
  border-radius: 5px;
  background-color: #0080ff;
}
.modal-footer.center.margin-bottom-10.footPaymentBox {
  padding-top: 1px;
  padding-bottom: 17px;
}
.modal-body.bodyPaymentBox {
  padding: 0px;
}
.tablePayment {
  width: 148px;
  height: 38px;
  font-family: "sf_compact_display";
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #9b9b9b;
}
.col-8.textPaymentLeft {
  text-align: left;
  font-size: 16px;
  color: #9b9b9b;
  font-family: "sf_compact_display";
  font-weight: 300;
}
.col-8.textPaymentLeftPlus {
  font-family: "Dosis";
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #007cff;
}
.col-4.textPaymentRight {
  text-align: right;
  font-size: 16px;
  color: #9b9b9b;
  font-family: "sf_compact_display";
  font-weight: 300;
  padding: 0px;
}
.col-4.textPaymentRightPlus {
  font-family: "Dosis";
  font-size: 20px;
  font-weight: bold;
  text-align: right;
  color: #007cff;
  padding: 0px;
}
.barPayments {
  width: 90%;
}
.subtitlePayment {
  font-family: "Dosis";
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #007cff;
  padding-bottom: 5px;
}
.form-group.col-6.boxCode {
  padding-right: 0px;
  margin-bottom: 0px;
}
.form-group.col-6.boxExpedition {
  padding-left: 0px;
  margin-bottom: 0px;
}
.codeBold {
  font-family: "sf_compact_display";
  font-size: 12px;
  color: #0080ff;
  line-height: 15px;
  font-weight: 500;
}
.textCode {
  font-family: "sf_compact_display";
  font-size: 12px;
  font-weight: 300;
  line-height: 1.25;
  text-align: left;
  color: #4a4a4a;
  padding-bottom: 18px;
}
.modal-dialog.modal-sm.modalPayment {
  max-width: 350px;
  position: relative;
}
.modal-dialog.modal-sm.modalPayment .close {
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
}
.form-control.textInput {
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #9b9b9b;
}
.row.nationBox {
  margin-top: 0px;
  padding-bottom: 24px;
}
.form-group.countryList {
  width: 100%;
}
