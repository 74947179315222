.graph-candidates {
  width: 100%;
  max-width: 900px;
  box-shadow: 0 0 10px 0 rgba(155, 155, 155, 0.5);
  border-radius: 5px;
  margin: 20px auto;
  position: relative;
}
.graph-header {
  background-image: linear-gradient(-1deg, #024efa 24%, #001fff 100%);
  z-index: 200;
  padding-bottom: 10px;
}
.graph-content {
  background-image: linear-gradient(-1deg, #0080ff 24%, #024efa 100%);
}
.graph-title {
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: bold;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.imgAvatar {
  top: 0;
  left: 0;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}
.graph-cursor {
  width: 100%;
}
.graph-cursor .before {
  right: 30px;
  position: absolute;
  top: 18px;
  cursor: pointer;
  width: 20px;
}
.graph-cursor .after {
  left: 30px;
  position: absolute;
  top: 18px;
  cursor: pointer;
  width: 20px;
}

.graph-candidates-avatars,
.graph-candidates-performance {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.graph-candidate-avatar .graph-avatar {
  width: 70px;
  height: 70px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin-left: 15px;
  margin-right: 15px;
  opacity: 0.8;
  position: relative;
}
.graph-candidate-avatar .graph-avatar .play {
  position: absolute;
  right: 21px;
  top: 20px;
  opacity: 0.5;
}
.graph-candidate-avatar .graph-avatar .play:hover {
  opacity: 1;
}
.graph-candidate-avatar.active .graph-avatar {
  opacity: 1;
}
.graph-candidate-avatar .graph-avatar-name {
  color: #ffffff;
  letter-spacing: 0;
  line-height: 14px;
  width: 70px;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
  margin-top: 10px;
  opacity: 0.5;
  font-size: 12px;
}

.graph-candidate-avatar.active .graph-avatar-name {
  font-weight: bold;
  opacity: 1;
  font-size: 14px;
}
.graph-separator {
  margin-bottom: 10px;
  text-align: center;
}
.graph-canvas > div {
  margin: 10px auto;
  padding-bottom: 50px;
}
.graph-separator img {
  height: 2px;
  max-width: 600px;
}
.graph-performance {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.graph-performance .number {
  opacity: 0.6;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0;
}
.graph-performance.active .number {
  font-weight: bold;
  font-size: 20px;
  opacity: 1;
}
.graph-performance .line {
  position: absolute;
  top: 38px;
}
.graph-hidden {
  display: none;
}
.boxGraph {
  margin-right: auto;
  margin-left: auto;
  width: 200px;
  height: 76px;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  -webkit-border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  padding-top: 9px;
  cursor: pointer;
}
.graphMaster {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  bottom: 0;
  position: absolute;
}
.inGraph {
  width: 90%;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  font-family: "Dosis";
  font-size: 16px;
  font-weight: bold;
  line-height: 0.88;
  color: #007cff;
  text-align: center;
}
.subGraph {
  font-family: "sf_compact_display";
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: #000000;
}

.onlyPrint {
  display: none;
}

.onlyPrint .graph-candidate-avatar .graph-avatar-name {
  color: black;
}

@media print {
  html,
  body {
    height: auto;
  }

  /* TODO: Change this to camelupper case to follow rules */
  .modal-dialog {
    padding: 50px;
  }

  .psychometricReport {
    /* page-break-after: avoid; */
    /* height: 30vh;  */
  }

  .page-printer-avatar {
    padding: 0 !important;
  }

  .feedbackModal {
    min-height: 50vh;
    max-height: 60vh;
    page-break-inside: avoid;
  }

  .graph-candidate-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  .graph-avatar-name {
    height: auto !important;
    width: auto !important;
    padding: 5px;
  }

  .detail-title {
    padding: 5px;
  }

  .page-printer {
    height: 100% !important;
    width: 100% !important;
    page-break-before: always;
  }

  .detail-competency {
    position: relative;
    margin-top: 0;
    /* page-break-inside:avoid;  */
  }

  .page-break-inside-avoid {
    page-break-inside: avoid;
  }

  .page-break-after-always {
    page-break-after: always;
  }

  .page-break-before-always {
    page-break-before: always;
  }

  .no-print {
    display: none;
  }

  .noPrint {
    display: none;
  }

  .sticky > div:nth-child(1) {
    display: none;
    visibility: collapse;
  }

  .onlyPrint {
    display: flex;
  }

  .feedbackModal {
    display: flex !important;
    position: inherit;
  }

  .feedbackModal .modal-dialog {
    width: 100%;
    max-width: inherit;
    box-shadow: none;
    border: none;
  }

  .feedbackModal .feedContainer {
    align-items: center;
    display: flex;
    border: none;
    background-color: #fff;
    /* height: 100% !important;
		width: 100% !important;
		page-break-after:always;  */
  }

  .feedbackModal .graph-cursor {
    display: none;
  }

  .feedbackModal .contFeedback .cancelSimbol {
    display: none;
  }

  .feedbackModal .canContPrincipalFeed {
    background: none;
  }

  .feedbackModal .titleBack,
  .feedbackModal .scoreFeed,
  .feedbackModal .txtScore {
    color: #0077ff;
  }

  .feedbackModal .txtScore {
    page-break-inside: avoid;
    /* page-break-after:avoid; */
  }
}
