.feedContainer {
  width: 100%;
  background: #f6f6f6;
}
.feedZoneTitle {
  width: 100%;
  background: #e3e3e3;
  height: 50px;
}
.feedTitle {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 25px;
  padding-top: 11px;
  color: #0077ff;
  letter-spacing: 0;
  text-align: center;
}
.graph-cursor {
  width: 95%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.graph-cursor .before {
  right: 30px;
  position: absolute;
  top: 40px;
  cursor: pointer;
  width: 20px;
}
.graph-cursor .after {
  left: 30px;
  position: absolute;
  top: 40px;
  cursor: pointer;
  width: 20px;
}
.canContPrincipalFeed {
  width: 100%;
  background: white;
  border-radius: 7px;

  background-image: url("../../images/feedback/backHeader.svg");
  background-repeat: no-repeat;
  background-size: content;
  max-width: 620px;
  background-size: contain;
}
.starBoxFeed {
  padding: 2px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: #50e3c2;
  margin-left: 38px;
  margin-top: 4px;
  position: absolute;
}
.backHeaderFeed {
  border-radius: 5px;
  z-index: 0;
  position: absolute;
}
.circleBack {
  z-index: 1;
  margin-top: 10px;
}
.rowButtons {
  width: 93%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 15px;
  z-index: 1;
  position: relative;
}
.txtDescription {
  font-size: 14px;
  line-height: 1.29;
  text-align: center;
  color: #4a4a4a;
}
.titleBack {
  height: 23px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.15;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
  width: 70%;
  z-index: 1;
  position: static;
  margin-left: auto;
  margin-right: auto;
}
.contTitleBack {
  padding-bottom: 21px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
.cancelSimbol {
  width: 19px;
  float: right;
  margin-right: 14px;
  margin-top: 20px;
  position: static;
  cursor: pointer;
}

.cancelRight {
  right: 10px;
}
.porcentageBack {
  height: 15px;
  font-size: 12px;
  text-align: left;
  color: #0077ff;
  width: 110px;
  height: 18px;
  border-radius: 100px;
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.contFeedback {
  padding-top: 2px;
  margin-bottom: 10px;
}
.reportButtonActiveFeed {
  color: white;
  width: 168px;
  background: #003878;
  padding-top: 14px;
  padding-bottom: 16px;
  text-align: center;
  border-radius: 30px;
  font-weight: bold;
  margin-right: 14px;
  float: left;
  font-size: 16px;
  box-shadow: 0px 0px 4px 0px rgba(83, 83, 83, 0.5);
  -webkit-box-shadow: 0px 0px 4px 0px rgba(83, 83, 83, 0.5);
  -moz-box-shadow: 0px 0px 4px 0px rgba(83, 83, 83, 0.5);
  border: 0px;
}
.reportButtonFeed {
  color: #8a8a8a;
  width: 168px;
  font-size: 16px;
  background: #ebebeb;
  padding-top: 14px;
  padding-bottom: 16px;
  text-align: center;
  border-radius: 30px;
  font-weight: 500;
  margin-right: 14px;
  float: left;
  border: 0px;
}
.scoreBox {
  background-image: url("../../images/feedback/circleBack.svg");
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  width: 106px;
  height: 117px;
  padding-top: 10px;
  position: relative;
}
.scoreFeed {
  font-size: 50px;
  font-weight: bold;
  text-align: left;
  color: #ffffff;
  text-align: center;
  padding-top: 14px;
  height: 135px;
  margin-top: 14px;
}
.txtScore {
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 0px;
  height: 14px;
  font-size: 12px;
  color: #ffffff;
}
.modal-backdrop {
  width: 100vw;
  height: 100vh;
}
